import { fullScreen } from "@/styles/content";
import { themes } from "@/styles/themes";
import { style } from "typestyle";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FullScreenLoadingSpinner extends Vue {
  public get styles() {
    const theme = themes.default;
    return {
      root: style({
        ...fullScreen,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }),

      title: style({
        color: theme.lightTextColor.toString(),
      }),

      spinner: style({
        $nest: {
          ".sk-child": {
            animationDuration: "2s",
          },
        },
        margin: 0,
        opacity: 0.1,
      }),
    };
  }

  public render() {
    const classes = this.styles;
    return (
      <div class={classes.root}>
        <div>
          <h2 class={classes.title}>Loading</h2>
          <div class={`${classes.spinner} sk-three-bounce`}>
            <div class="sk-child sk-bounce1" />
            <div class="sk-child sk-bounce2" />
            <div class="sk-child sk-bounce3" />
          </div>
        </div>
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-ee758230/FullScreenLoadingSpinner.tsx" });