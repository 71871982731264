import { Component, Prop, Vue } from "vue-property-decorator";
import EventSection from "./EventSection";
import EventSectionSeperator from "./EventSectionSeperator";

@Component
export default class EventSectionList extends Vue {
  @Prop({ required: true }) public event!: IEvent;
  @Prop({ required: true }) public theme!: IEventTheme;

  get styles() {
    return {
      ...this.theme.classes,
    };
  }

  public render() {
    const sections = this.event.fields.sections.filter(s => !!s.fields);
    const theme = this.theme;

    return (
      <div>
        {sections.map(section => (
          <div>
            <EventSection props={{ section, theme }} />
            <EventSectionSeperator />
          </div>
        ))}
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-9c71f0ce/EventSectionList.tsx" });