import { scrollToElement } from "@/lib/scrollToElement";
import { textLight } from "@/styles/colors";
import { ColorHelper, em, rem } from "csx";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BackToTop extends Vue {
  @Prop({ default: () => textLight }) public color!: ColorHelper;

  public render() {
    const classes = this.styles;
    return (
      <a href="#app" class={classes.root} onClick={this.scrollToTop}>
        &uarr; Back to top
      </a>
    );
  }

  private scrollToTop(event: MouseEvent) {
    event.preventDefault();

    if (history.pushState) {
      history.pushState(null, "", "#top");
    }

    const top = document.getElementById("top")!;
    scrollToElement(top);
  }

  private get styles() {
    return {
      root: style({
        color: this.color.toString(),
        fontSize: em(1),
        position: "absolute",
        right: 0,
        textDecoration: "none",
        top: rem(2),
        zIndex: 2,
      }),
    };
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-52fc08f8/BackToTop.tsx" });