import { content, section } from "@/styles/content";
import { margin } from "csstips";
import { em, percent } from "csx";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import BackToTop from "./lib/BackToTop";
import FaqItem from "./lib/FaqItem";

@Component({
  components: { BackToTop },
})
export default class FaqsSection extends Vue {
  @Prop({ required: true }) public section!: IFaqsSection;
  @Prop({ required: true }) public theme!: IEventTheme;

  private get styles() {
    const theme = this.theme;
    return {
      ...theme.classes,

      heading: style({
        ...theme.display1,
        marginTop: 0,
      }),

      root: style({
        ...content,
        ...section,
      }),

      container: style({
        width: percent(100),
      }),

      items: style({
        ...margin(em(-1.5)),
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "stretch",
      }),
    };
  }

  public render() {
    const classes = this.styles;
    const fields = this.section.fields;
    const theme = this.theme;
    return (
      <section class={classes.root}>
        <back-to-top color={theme.lightTextColor} />
        <h3 class={classes.heading}>{fields.title}</h3>

        <div class={classes.container}>
          <div class={classes.items}>
            {fields.items.map(item => (
              <FaqItem props={{ theme, item }} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-c8be7d52/FaqsSection.tsx" });