import { content, section } from "@/styles/content";
import { themes } from "@/styles/themes";
import { em, percent } from "csx";
import { style } from "typestyle";
import url from "url";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SiteFooter extends Vue {
  @Prop({ required: true }) public event!: IEvent;
  @Prop({ default: () => themes.default }) public theme!: IEventTheme;

  private get styles() {
    const theme = this.theme;
    return {
      ...theme.classes,
      content: style({
        ...content,
        ...section,
        alignItems: "flex-start",
        backgroundColor: this.theme.backgroundColor.toString(),
        display: "flex",
        flexDirection: "row",
      }),
      details: style({
        $nest: {
          a: theme.link,
          strong: {
            display: "inline-block",
            width: em(1.5),
          },
        },
        minWidth: em(15),
      }),
      heading: style({
        ...this.theme.title,
        marginBottom: em(1),
        marginTop: 0,
      }),
      logo: style({
        $nest: {
          img: {
            display: "block",
            height: percent(100),
            width: percent(100),
          },
        },
        display: "inline-block",
        width: em(10),
      }),
      root: style({
        backgroundColor: this.theme.backgroundColor.toString(),
      }),
      spacer: style({
        width: em(5),
      }),
      subHeading: style({
        ...this.theme.subtitle,
        fontSize: em(1),
        marginBottom: em(0.5),
        marginTop: 0,
      }),
    };
  }

  public render() {
    const classes = this.styles;
    const fields = this.event.fields;
    return (
      <footer class={classes.root}>
        <div class={classes.content}>
          <a href="https://arisechurch.com" class={classes.logo}>
            <img src="/img/arise-logo.svg" alt="ARISE" />
          </a>

          <div class={classes.spacer} />

          <div class={classes.details}>
            <h5 class={classes.subHeading}>Delegate Info</h5>
            <h4 class={classes.heading}>{fields.name}</h4>
            <strong>P:</strong>&nbsp;
            <a href="tel:+6444990477">+64 4 499 0477</a>
            <br />
            <strong>E:</strong>&nbsp;
            <a href={`mailto:${fields.email}`}>{fields.email}</a>
            <br />
            <strong>W:</strong>&nbsp;
            <a href={fields.website}>{url.parse(fields.website).host}</a>
          </div>
        </div>
      </footer>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-8c35df1c/SiteFooter.tsx" });