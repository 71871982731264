import marked from "marked";
import { style } from "typestyle";
import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ListItem from "./lib/ListItem";
import ListSectionBuilder from "./ListSectionBuilder";

@Component({
  components: {
    ListItem,
    ListSectionBuilder,
  },
})
export default class AccommodationSection extends Vue {
  @Prop({ required: true }) private section!: IAccommodationSection;
  @Prop({ required: true }) private theme!: IEventTheme;

  private get styles() {
    return {
      link: style({
        display: "block",
      }),
      list: style({
        listStyle: "none",
        margin: 0,
        padding: 0,
      }),
    };
  }

  public render() {
    const { title, items } = this.section.fields;
    return (
      <list-section-builder
        theme={this.theme}
        title={title}
        itemCount={items.length}
        itemBuilder={(i: number) => this.buildItem(i)}
      />
    );
  }

  private buildItem(index: number): VNode {
    const classes = this.styles;
    const item = this.section.fields.items[index];
    const {
      name,
      pricing,
      discountCode,
      phone,
      email,
      conditions,
      website,
    } = item.fields;
    const html = conditions ? marked(conditions) : "";

    return (
      <list-item title={name} theme={this.theme.light}>
        <p>
          <strong>{pricing}</strong>
        </p>

        <ul class={classes.list}>
          {discountCode && (
            <li>
              <strong>Discount code:</strong> {discountCode}
            </li>
          )}
          {phone && (
            <li>
              <strong>Phone:</strong>{" "}
              <a href={`tel:${phone.replace(/[^0-9]/g, "")}`}>{phone}</a>
            </li>
          )}
        </ul>

        {conditions && <div domProps={{ innerHTML: html }} />}

        {email && (
          <a class={classes.link} href={`mailto:${email}`}>
            Send an email &rarr;
          </a>
        )}
        {website && (
          <a class={classes.link} href={website}>
            Visit website &rarr;
          </a>
        )}
      </list-item>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-39998c8c/AccommodationSection.tsx" });