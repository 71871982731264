import { content, section } from "@/styles/content";
import { em, percent } from "csx";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import BackToTop from "./lib/BackToTop";
import ProgrammeDay from "./lib/ProgrammeDay";

export interface IProgrammeDay {
  heading: IProgrammeItem;
  items: IProgrammeItem[];
}

@Component({
  components: { BackToTop },
})
export default class ProgrammeSection extends Vue {
  @Prop({ required: true }) public section!: IProgrammeSection;
  @Prop({ required: true }) public theme!: IEventTheme;

  private get styles() {
    const theme = this.theme;
    return {
      root: style({
        ...content,
        ...section,
      }),

      heading: style({
        ...theme.display1,
        marginTop: 0,
      }),

      items: style({
        display: "flex",
        flexWrap: "wrap",
        margin: em(-1),
        width: percent(100),
      }),
    };
  }

  private get programmeDays(): IProgrammeDay[] {
    const items = this.section.fields.items;
    const days: IProgrammeDay[] = [];
    let currentDay: IProgrammeDay;

    items.forEach(item => {
      const fields = item.fields;
      if (!currentDay || fields.type === "heading") {
        if (currentDay) {
          days.push(currentDay);
        }
        currentDay = {
          heading: item,
          items: [],
        };
      } else {
        currentDay.items.push(item);
      }
    });

    days.push(currentDay!);
    return days;
  }

  public render() {
    const classes = this.styles;
    const theme = this.theme;
    const fields = this.section.fields;

    return (
      <div class={classes.root}>
        <back-to-top color={this.theme.lightTextColor} />
        <h3 class={classes.heading}>{fields.title}</h3>

        <div class={classes.items}>
          {this.programmeDays.map(day => (
            <ProgrammeDay props={{ day, theme }} />
          ))}
        </div>
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-70d46aa0/ProgrammeSection.tsx" });