import { margin } from "csstips";
import { em, percent } from "csx";
import { media, style } from "typestyle";
import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ListBuilder extends Vue {
  @Prop({ required: true }) public theme!: IEventTheme;
  @Prop({ required: true }) public itemBuilder!: (index: number) => VNode;
  @Prop({ required: true }) public itemCount!: number;

  private get styles() {
    const theme = this.theme;
    return {
      ...theme.classes,
      list: style(
        {
          ...margin(em(-1), em(-3)),
          alignItems: "stretch",
          display: "flex",
          flexWrap: "wrap",
          listStyle: "none",
          padding: 0,
        },
        media({ maxWidth: 500 }, margin(em(-1), em(-1))),
      ),
      listWrap: style({
        width: percent(100),
      }),
    };
  }

  public render() {
    const classes = this.styles;

    return (
      <div class={classes.listWrap}>
        <ul class={classes.list}>
          {Array.from({ length: this.itemCount }, this.buildItem)}
        </ul>
      </div>
    );
  }

  private buildItem(_: any, index: number): VNode {
    return this.itemBuilder(index);
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-0ea4613a/ListBuilder.tsx" });