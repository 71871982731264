import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AccommodationSection from "./sections/AccommodationSection";
import FaqsSection from "./sections/FaqsSection";
import ListSection from "./sections/ListSection";
import MapSection from "./sections/MapSection";
import MarkdownSection from "./sections/MarkdownSection";
import ProgrammeSection from "./sections/ProgrammeSection";
import RecordingsSection from "./sections/RecordingsSection";

@Component({
  components: {
    AccommodationSection,
    FaqsSection,
    ListSection,
    MapSection,
    MarkdownSection,
    ProgrammeSection,
    RecordingsSection,
  },
})
export default class EventSection extends Vue {
  @Prop({ required: true }) public section!: TEventSection;
  @Getter("events/theme") public theme!: IEventTheme;

  private get sectionTheme() {
    return this.theme[this.section.fields.theme || "light"];
  }

  private get styles() {
    const theme = this.sectionTheme;
    return {
      root: style({
        ...theme.body1,
        backgroundColor: theme.backgroundColor.toString(),
      }),
    };
  }

  public render() {
    const classes = this.styles;
    const section = this.section;
    const theme = this.sectionTheme;
    let tagName = "div";

    switch (this.section.sys.contentType.sys.id) {
      case "accommodationSection":
        tagName = "accommodation-section";
        break;
      case "faqsSection":
        tagName = "faqs-section";
        break;
      case "listSection":
        tagName = "list-section";
        break;
      case "markdownSection":
        tagName = "markdown-section";
        break;
      case "mapSection":
        tagName = "map-section";
        break;
      case "programmeSection":
        tagName = "programme-section";
        break;
      case "recordingsSection":
        tagName = "recordings-section";
        break;
    }

    return (
      <div id={section.fields.slug} class={classes.root}>
        <tagName section={section} theme={theme} />
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-d88f2c4a/EventSection.tsx" });