import { content, section, textContent } from "@/styles/content";
import marked from "marked";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import BackToTop from "./lib/BackToTop";

@Component({
  components: { BackToTop },
})
export default class MarkdownSection extends Vue {
  @Prop({ required: true }) public section!: IMarkdownSection;
  @Prop({ required: true }) public theme!: IEventTheme;

  private get styles() {
    return {
      ...this.theme.classes,

      root: style({
        ...content,
        ...section,
      }),

      heading: style({
        ...this.theme.display1,
        marginTop: 0,
      }),

      content: style(textContent),
    };
  }

  private get htmlBody() {
    return marked(this.section.fields.body);
  }

  public render() {
    const classes = this.styles;
    const sectionFields = this.section.fields;
    return (
      <div class={classes.root}>
        <back-to-top color={this.theme.lightTextColor} />
        <h3 class={classes.heading}>{sectionFields.title}</h3>
        <div
          class={[classes.content, classes.wysiwyg].join(" ")}
          domProps={{ innerHTML: this.htmlBody }}
        />
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-11bc1976/MarkdownSection.tsx" });