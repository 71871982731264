import { black } from "@/styles/colors";
import { px } from "csx";
import { style } from "typestyle";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class EventSectionSeperator extends Vue {
  public get styles() {
    return {
      root: style({
        backgroundBlendMode: "multiply",
        backgroundColor: black.fadeOut(0.9).toString(),
        border: 0,
        height: px(1),
        margin: 0,
        marginTop: px(-1),
      }),
    };
  }
  public render() {
    return <hr class={this.styles.root} />;
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-79cc96bc/EventSectionSeperator.tsx" });