import { link, shadow, white } from "@/styles/colors";
import { content } from "@/styles/content";
import { mapSectionStyles } from "@/styles/maps/mapSectionStyles";
import * as csstips from "csstips";
import { em, important, percent, viewHeight } from "csx";
import GoogleMaps from "google-maps";
import { media, style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import BackToTop from "./lib/BackToTop";

@Component({
  components: { BackToTop },
})
export default class MapSection extends Vue {
  @Prop({ required: true }) public section!: IMapSection;
  @Prop({ required: true }) public theme!: IEventTheme;

  private get styles() {
    const theme = this.theme.light;
    return {
      ...theme.classes,

      root: style({
        height: viewHeight(90),
        position: "relative",
      }),

      heading: style({
        ...theme.display1,
        left: 0,
        position: "absolute",
        top: em(1),
        zIndex: 1,
      }),

      content: style({
        ...content,
        position: "relative",
      }),

      actions: style({
        ...csstips.padding(em(0.25), em(0.5)),
        backgroundColor: white.toString(),
        borderRadius: em(0.25),
        boxShadow: [
          em(0),
          em(0.25),
          em(0.5),
          shadow.fadeOut(0.3).toString(),
        ].join(" "),
        right: important(em(-0.5).toString()),
      }),

      map: style({
        height: percent(100),
        width: percent(100),
        zIndex: 0,
      }),
    };
  }

  public mounted() {
    const sectionFields = this.section.fields;
    GoogleMaps.load(google => {
      const map = new google.maps.Map(this.$refs.map as Element, {
        center: {
          lat: sectionFields.center.lat,
          lng: sectionFields.center.lon,
        },
        mapTypeControl: false,
        styles: mapSectionStyles,
        zoom: this.section.fields.zoom,
      });
      this.setupMap(google, map);
    });
  }

  public render() {
    const theme = this.theme;
    const classes = this.styles;
    const sectionFields = this.section.fields;
    return (
      <div id={sectionFields.slug} class={classes.root}>
        <div class={classes.content}>
          <h3 class={classes.heading}>{sectionFields.title}</h3>
          <back-to-top color={theme.lightTextColor} class={classes.actions} />
        </div>
        <div ref="map" class={classes.map} />
      </div>
    );
  }

  private parkingMarkerOptions = (google: GoogleMaps.google) => ({
    icon: {
      fillColor: link.toString(),
      fillOpacity: 1,
      path: google.maps.SymbolPath.CIRCLE,
      scale: 12,
      strokeColor: white.toString(),
      strokeWeight: 2,
    },
    label: {
      color: white.toString(),
      fontWeight: "800",
      text: "P",
    },
  });

  private directionsTo(location: IContentfulLocation) {
    return `https://www.google.com/maps/dir/?api=1&origin=&destination=${
      location.lat
    },${location.lon}`;
  }

  private setupMap(google: GoogleMaps.google, map: google.maps.Map) {
    this.section.fields.locations.forEach(location => {
      const fields = location.fields;
      let options: google.maps.MarkerOptions = {
        map,
        position: {
          lat: fields.location.lat,
          lng: fields.location.lon,
        },
        title: fields.name,
      };

      if (fields.markerType === "parking") {
        options = {
          ...this.parkingMarkerOptions(google),
          ...options,
        };
      }
      const info = new google.maps.InfoWindow({
        content: `<h3 style="margin: 0;">${fields.name}</h3>
        <p>${fields.description}</p>
        <a href="${this.directionsTo(fields.location)}">Directions</a>`,
      });

      const marker = new google.maps.Marker(options);
      marker.addListener("click", () => {
        info.open(map, marker);
      });
    });
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-8f1fc520/MapSection.tsx" });