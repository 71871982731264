import EventSectionList from "@/components/EventSectionList";
import FullScreenLoadingSpinner from "@/components/FullScreenLoadingSpinner";
import FullScreenNotFound from "@/components/FullScreenNotFound";
import SiteFooter from "@/components/SiteFooter";
import { scrollToElement } from "@/lib/scrollToElement";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const eventsModule = namespace("events");

@Component({
  metaInfo(this: Event) {
    return {
      title: this.event ? this.event.fields.name : "Loading...",
    };
  },
})
export default class Event extends Vue {
  @eventsModule.State("currentEvent") public event?: IEvent;
  @eventsModule.State("currentLoading") public loading!: boolean;
  @eventsModule.Getter("theme") public theme!: IEventTheme;

  @eventsModule.Action("fetchCurrent")
  public fetchCurrent!: (slug: string) => Promise<void>;

  public created() {
    this.fetchCurrent(this.$route.params.slug);
  }

  public scrollToHash() {
    if (!window.location.hash) {
      return;
    }

    const el = document.querySelector(window.location.hash);
    if (el) {
      scrollToElement(el);
    }
  }

  public render() {
    return (
      <div class={`event ${this.theme.classes.document}`}>
        <transition
          name="fade"
          mode="out-in"
          onAfterEnter={this.scrollToHash}
          onBeforeEnter={this.eventLoaded}
        >
          {this.loading ? (
            <FullScreenLoadingSpinner />
          ) : !this.event ? (
            <FullScreenNotFound />
          ) : (
            this.renderEvent()
          )}
        </transition>
      </div>
    );
  }

  private renderEvent() {
    const Header = this.theme.header;

    return (
      <div class={this.theme.classes.document}>
        <Header props={{ event: this.event!, theme: this.theme.dark }} />
        <EventSectionList props={{ event: this.event!, theme: this.theme }} />
        <SiteFooter props={{ theme: this.theme.footer, event: this.event }} />
      </div>
    );
  }

  private eventLoaded() {
    if (this.theme.injectFonts) {
      this.theme.injectFonts();
    }
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-2cc7f790/Event.tsx" });