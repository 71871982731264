import { google } from "google-maps";

export const mapSectionStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text",
    featureType: "poi.park",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels",
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text",
    featureType: "water",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];
