import marked from "marked";
import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ListItem from "./lib/ListItem";
import ListSectionBuilder from "./ListSectionBuilder";

@Component({
  components: {
    ListItem,
    ListSectionBuilder,
  },
})
export default class ListSection extends Vue {
  @Prop({ required: true }) private section!: IListSection;
  @Prop({ required: true }) private theme!: IEventTheme;

  public render() {
    const { title, items } = this.section.fields;
    return (
      <list-section-builder
        theme={this.theme}
        title={title}
        itemCount={items.length}
        itemBuilder={(i: number) => this.buildItem(i)}
      />
    );
  }

  private buildItem(index: number): VNode {
    const item = this.section.fields.items[index];
    const { title, body } = item.fields;
    const html = marked(body);

    return (
      <list-item title={title} theme={this.theme.light}>
        <div
          class={this.theme.classes.wysiwyg}
          domProps={{ innerHTML: html }}
        />
      </list-item>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-ab298394/ListSection.tsx" });