import { content, section } from "@/styles/content";
import { style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import ListBuilder from "../ListBuilder";
import BackToTop from "./lib/BackToTop";
import ListItem from "./lib/ListItem";

interface IRecordingListItem {
  productIdField: "audioProductId" | "videoProductId";
  productUrlField: "audioProductUrl" | "videoProductUrl";
  title: string;
}

@Component({
  components: { BackToTop, ListBuilder },
})
export default class RecordingsSection extends Vue {
  private get styles() {
    return {
      ...this.theme.classes,

      root: style({
        ...content,
        ...section,
      }),

      heading: style({
        ...this.theme.display1,
        marginTop: 0,
      }),

      button: style({
        ...this.theme.smallTitle,
        ...this.theme.link,
        $nest: {
          "&:disabled": {
            opacity: 0,
          },
        },
        background: "transparent",
        border: 0,
        opacity: 1,
        transition: "opacity 200ms ease",
      }),
    };
  }

  private get productId() {
    const { videoProductId, audioProductId } = this.section.fields;
    const firstLetter = this.code[0];
    if (!firstLetter) {
      return videoProductId;
    }

    return firstLetter.toLowerCase() === "a" ? audioProductId : videoProductId;
  }

  private get formAction() {
    return this.buildUrl(this.productId);
  }
  public static listItems: IRecordingListItem[] = [
    {
      productIdField: "audioProductId",
      productUrlField: "audioProductUrl",
      title: "Audio",
    },
    {
      productIdField: "videoProductId",
      productUrlField: "videoProductUrl",
      title: "Video",
    },
  ];

  @Prop({ required: true }) public section!: IRecordingsSection;
  @Prop({ required: true }) public theme!: IEventTheme;
  private code: string = "";

  public render() {
    const classes = this.styles;
    const sectionFields = this.section.fields;
    return (
      <div class={classes.root}>
        <back-to-top color={this.theme.lightTextColor} />
        <h3 class={classes.heading}>{sectionFields.title}</h3>

        {sectionFields.recordingsUrl && (
          <div>
            <h4 class={classes.title}>Have a recording card?</h4>
            <a class={classes.link} href={sectionFields.recordingsUrl}>
              Click here to access your recordings.
            </a>
          </div>
        )}

        <h4 class={classes.title}>Buy the recordings:</h4>
        <list-builder
          itemBuilder={(i: number) => this.renderListItem(i)}
          itemCount={RecordingsSection.listItems.length}
          theme={this.theme.light}
        />
      </div>
    );
  }

  private buildUrl(productId: string) {
    return `https://shop.arisechurch.com/cart/${productId}:1`;
  }

  private renderListItem(index: number) {
    const { title, productIdField } = RecordingsSection.listItems[index];
    const fields = this.section.fields;
    const productId = fields[productIdField];
    const theme = this.theme.light;
    const description =
      productIdField === "videoProductId"
        ? "Includes both Video and Audio recordings."
        : "Include Audio recordings for all main sessions and electives.";

    return (
      <ListItem props={{ title, theme }}>
        <p>{description}</p>
        <a href={this.buildUrl(productId)}>Buy {title} Recordings &rarr;</a>
      </ListItem>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-6b342e9c/RecordingsSection.tsx" });