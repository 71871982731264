import { border, em, percent, quote, rem } from "csx";
import marked from "marked";
import { media, style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FaqItem extends Vue {
  private get styles() {
    const theme = this.theme;

    const leftBorder = (text: string): NestedCSSProperties => ({
      $nest: {
        "&:before": {
          ...theme.smallTitle,
          content: quote(text),
          display: "inline",
          left: rem(-2),
          position: "absolute",
          textAlign: "right",
        },
      },
      borderLeft: border({
        color: theme.textColor.toString(),
        style: "solid",
        width: rem(0.4),
      }),
      // marginLeft: rem(1.7),
      paddingLeft: rem(1),
      position: "relative",
    });

    return {
      ...theme.classes,

      root: style(
        {
          boxSizing: "border-box",
          padding: em(1.5),
          width: percent(50),
        },
        media({ maxWidth: 1000 }, { width: percent(100) }),
      ),

      question: style({
        ...theme.smallTitle,
        ...leftBorder("Q"),
        marginBottom: em(0.5),
        marginTop: em(0.5),
        paddingBottom: em(0.5),
        paddingTop: em(0.5),
      }),

      answer: style({
        ...leftBorder("A"),
      }),
    };
  }

  private get answerHTML() {
    return marked(this.item.fields.answer);
  }
  @Prop({ required: true }) public theme!: IEventTheme;
  @Prop({ required: true }) public item!: IFaqItem;

  private showAnswer = false;

  public render() {
    const classes = this.styles;
    const fields = this.item.fields;
    return (
      <div class={classes.root}>
        <div>
          <h4 class={[classes.question]}>{fields.question}</h4>
          <div class={[classes.answer, classes.wysiwyg].join(" ")}>
            {this.showAnswer && (
              <div domProps={{ innerHTML: this.answerHTML }} />
            )}
            <a href="#" onClick={this.toggleAnswer}>
              {this.showAnswer ? (
                <span>Hide answer &uarr;</span>
              ) : (
                <span>Show answer &darr;</span>
              )}
            </a>
          </div>
        </div>
      </div>
    );
  }

  private toggleAnswer(event: Event) {
    event.preventDefault();
    this.showAnswer = !this.showAnswer;
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-7932338e/FaqItem.tsx" });