import { content, section, textContent } from "@/styles/content";
import { margin } from "csstips";
import { em, percent } from "csx";
import { media, style } from "typestyle";
import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import ListBuilder from "../ListBuilder";
import BackToTop from "./lib/BackToTop";

@Component({ components: { BackToTop, ListBuilder } })
export default class ListSectionBuilder extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public theme!: IEventTheme;
  @Prop({ required: true }) public itemBuilder!: (index: number) => VNode;
  @Prop({ required: true }) public itemCount!: number;

  private get styles() {
    const theme = this.theme;
    return {
      ...theme.classes,
      content: style(textContent),
      heading: style({
        ...this.theme.display1,
        marginTop: 0,
      }),
      root: style({
        ...content,
        ...section,
      }),
    };
  }

  public render() {
    const classes = this.styles;
    const theme = this.theme;

    return (
      <div class={classes.root}>
        <back-to-top color={theme.lightTextColor} />
        <h3 class={classes.heading}>{this.title}</h3>

        <list-builder
          theme={theme}
          itemBuilder={this.itemBuilder}
          itemCount={this.itemCount}
        />
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-d9b5a18a/ListSectionBuilder.tsx" });