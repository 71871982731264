import { bgLightGrey, shadow } from "@/styles/colors";
import { em, percent } from "csx";
import { media, style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ListItem extends Vue {
  @Prop({ required: true }) private theme!: IEventTheme;
  @Prop() private title?: string;

  private get styles() {
    const theme = this.theme;

    return {
      item: style(
        {
          $nest: {
            a: theme.link,
          },
          ...this.theme.card,
          height: percent(100),
        },
        media({ maxWidth: 500 }, { padding: em(1.5) }),
      ),
      itemContent: style({
        $nest: {
          ":last-child": {
            marginBottom: 0,
          },
        },
      }),
      itemTitle: style({
        ...theme.light.smallTitle,
        marginBottom: em(0.5),
        marginTop: 0,
      }),
      itemWrap: style(
        {
          ...theme.light.body1,
          boxSizing: "border-box",
          padding: em(1),
          width: percent(33.33333),
        },
        media({ maxWidth: 1300 }, { width: percent(50) }),
        media({ maxWidth: 700 }, { width: percent(100) }),
      ),
    };
  }

  public render() {
    const classes = this.styles;
    return (
      <li class={classes.itemWrap}>
        <div class={classes.item}>
          {this.title ? <h4 class={classes.itemTitle}>{this.title}</h4> : null}
          {this.$slots.default}
        </div>
      </li>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-225d3ebc/ListItem.tsx" });