import { fullScreen } from "@/styles/content";
import { style } from "typestyle";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FullScreenNotFound extends Vue {
  get styles() {
    return {
      root: style({
        ...fullScreen,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }),
    };
  }
  public render() {
    const classes = this.styles;
    return (
      <div class={classes.root}>
        <h2>404 &mdash; Page not found.</h2>
      </div>
    );
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-575b411a/FullScreenNotFound.tsx" });