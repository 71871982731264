import { border, em, percent } from "csx";
import marked from "marked";
import { media, style } from "typestyle";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IProgrammeDay } from "../ProgrammeSection";

@Component
export default class ProgrammeDay extends Vue {
  @Prop({ required: true }) public day!: IProgrammeDay;
  @Prop({ required: true }) public theme!: IEventTheme;

  private get styles() {
    const theme = this.theme;
    return {
      ...theme.classes,

      root: style(
        {
          boxSizing: "border-box",
          padding: em(1),
          width: percent(50),
        },
        media({ maxWidth: 950 }, { width: percent(100) }),
      ),

      border: style({
        borderLeft: border({
          style: "solid",
          width: em(0.4),
        }),
        paddingLeft: em(1),
      }),

      heading: style({
        ...theme.smallTitle,
        marginTop: 0,
      }),

      list: style({
        $nest: {
          ":last-child": {
            marginBottom: 0,
          },
        },
        listStyle: "none",
        padding: 0,
      }),

      note: style({
        fontStyle: "italic",
      }),

      spacer: style({
        marginBottom: em(0.5),
      }),
    };
  }

  public render() {
    const classes = this.styles;
    const { heading, items } = this.day;
    return (
      <div class={classes.root}>
        <div class={classes.border}>
          {this.renderHeading(heading)}
          <ul class={classes.list}>
            {items.map(item => this.renderItem(item))}
          </ul>
        </div>
      </div>
    );
  }

  private renderHeading(heading: IProgrammeItem) {
    const classes = this.styles;

    if (heading.fields.time) {
      return (
        <h4 class={classes.heading}>
          {heading.fields.label}
          <br />
          <small>{heading.fields.time}</small>
        </h4>
      );
    }

    return <h4 class={classes.smallTitle}>{heading.fields.label}</h4>;
  }

  private renderItem(item: IProgrammeItem) {
    const fields = item.fields;
    const classes = this.styles;
    if (fields.type === "time") {
      return (
        <li>
          {fields.time} &mdash; <strong>{fields.label}</strong>
        </li>
      );
    } else if (fields.type === "spacer") {
      return <li class={classes.spacer} />;
    } else if (fields.type === "note") {
      const html = marked(fields.notes || fields.label);
      return <li class={classes.note} domProps={{ innerHTML: html }}></li>;
    }
    return null;
  }
}

// VUE JSX HOT LOADER //
if (module.hot) require("/Users/tim/code/arise/delegate-info/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-d9566f9e/ProgrammeDay.tsx" });